<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-6 col-sm-12 d-flex justify-content-center">
        <SendFiles
          ref="fileComponent"
          :logo="empresa.logo"
          @fileAddOrchanged="setNewFile($event)"
        />
      </div>
    </div>
    <b-form @submit.stop.prevent="salvar()">
      <div class="row">
        <div class="col-12">
          <label>Razão Social</label>
          <b-input
            type="text"
            ref="razao_socialInput"
            v-model="$v.empresa.razao_social.$model"
            placeholder="Razão Social"
            :state="validateState('razao_social')"
          />
          <b-form-invalid-feedback>
            Informe uma razão social válida
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <label>Fantasia</label>
          <b-input
            ref="fantasiaInput"
            type="text"
            v-model="empresa.fantasia"
            placeholder="Fantasia"
          />
          <b-form-invalid-feedback>
            Informe uma fantasia válida
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <label>E-mail</label>
          <b-input
            ref="emailInput"
            type="text"
            v-model="empresa.email"
            placeholder="E-mail"
          />
          <b-form-invalid-feedback>
            Informe um e-mail válido
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label>Nome / Apelido</label>
          <b-input
            ref="nomeInput"
            type="text"
            placeholder="Nome"
            v-model="$v.empresa.nome.$model"
            :state="validateState('nome')"
          />
          <b-form-invalid-feedback>
            Informe um nome válido
          </b-form-invalid-feedback>
        </div>

        <div class="col-6">
          <label>CNPJ</label>
          <b-input
            type="text"
            ref="cnpjInput"
            v-model="$v.empresa.cnpj.$model"
            v-mask="'##.###.###/####-##'"
            placeholder="CNPJ"
            :state="validateState('cnpj')"
          />
          <b-form-invalid-feedback>
            Informe uma CNPJ válido
          </b-form-invalid-feedback>
        </div>
      </div>
      <b-tabs
        class="mt-3"
        content-class="p-0"
        fill
        vertical
        card
        pills
      >
        <b-tab
          @click.prevent="hasErrors = false"
          class="p-4"
        >
          <template #title>
            <i class="fas fa-cogs" /> Geral
            <b-icon
              v-if="hasErrors"
              animation="fade"
              icon="exclamation-circle-fill"
              variant="danger"
            />
          </template>
          <div class="row">
            <div
              class="col-12 mb-2"
              v-if="$store.state.auth.user.super"
            >
              <b-card>
                <strong>Modo Sistema</strong>
                <b-select
                  v-model="empresa.configs.modoSistema"
                  :options="[
                    {
                      value: 1,
                      text: 'Restaurante Padrão',
                    },
                    {
                      value: 2,
                      text: 'Restaurante/Locação',
                    },
                  ]"
                ></b-select>
              </b-card>
            </div>
            <div class="col-12">
              <b-card>
                <b-card-title> Centro de Custo Padrão </b-card-title>
                <b-card-text>
                  <select-centro-custo
                    :lista="custos"
                    :custoId="empresa.configuracoes.centro_custo_id"
                    @change="
                      (ev) => (empresa.configuracoes.centro_custo_id = ev)
                    "
                  >
                  </select-centro-custo>
                </b-card-text>
              </b-card>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-lg-6">
              <b-card class="">
                <h4><b-icon-percent /> Taxas e Fretes</h4>
                <b-form-group>
                  <div class="row">
                    <div class="col-12 col-sm-12">
                      <label>Taxa Serviço</label>
                      <b-input
                        v-model.lazy="
                          $v.empresa.configuracoes.taxa_servico.$model
                        "
                        v-money="money"
                        :state="validateState('configuracoes', 'taxa_servico')"
                      />
                    </div>

                    <div class="col-12 mt-4">
                      <b
                        ><i class="fa fa-motorcycle"></i> Pagamento para
                        Entregador</b
                      >
                      <hr />
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-6">
                              <b>Cobrar Pedido Padrão</b>
                              <b-input
                                v-model="
                                  $v.empresa.configuracoes.frete_padrao.$model
                                "
                                v-money="money"
                                :state="
                                  validateState('configuracoes', 'frete_padrao')
                                "
                              />
                            </div>
                            <div class="col-6">
                              <b>Pagar Pedido Padrão</b>

                              <b-input
                                v-model="
                                  $v.empresa.configuracoes.frete_pg_padrao
                                    .$model
                                "
                                v-money="money"
                                :state="
                                  validateState(
                                    'configuracoes',
                                    'frete_pg_padrao'
                                  )
                                "
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <b>Cobrar Pedido Online</b>
                              <b-input
                                v-model="
                                  $v.empresa.configuracoes.frete_online.$model
                                "
                                v-money="money"
                                :state="
                                  validateState('configuracoes', 'frete_online')
                                "
                              />
                            </div>
                            <div class="col-6">
                              <b>Pagar Pedido Online</b>

                              <b-input
                                v-model="
                                  $v.empresa.configuracoes.frete_pg_online
                                    .$model
                                "
                                v-money="money"
                                :state="
                                  validateState(
                                    'configuracoes',
                                    'frete_pg_online'
                                  )
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form-group>
              </b-card>
              <b-card
                class="mt-3"
                v-if="empresa && empresa.configs && empresa.configs.dashboard"
              >
                <h4><b-icon-bar-chart /> Configurações Dashboard</h4>
                <b class="mt-2">Status de Pedidos para Resumo</b>
                <v-select
                  multiple
                  :options="[
                    { vl: 1, label: 'Pendente' },
                    { vl: 2, label: 'Em Produção' },
                    { vl: 3, label: 'Em rota / Retirar' },
                    { vl: 4, label: 'Concluído' },
                    { vl: -1, label: 'Cancelados' },
                  ]"
                  label="label"
                  :reduce="(sel) => sel.vl"
                  v-model="empresa.configs.dashboard.tiposStatusPedidosResumo"
                />
                <hr />
                <b>
                  <b-checkbox
                    switch
                    v-model="empresa.configs.dashboard.porFormaPagamento"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Exibir Pedidos por Forma de Pagamento
                  </b-checkbox>
                </b>
                <b>
                  <b-checkbox
                    switch
                    v-model="empresa.configs.dashboard.somarOnline"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Somar vendas *ONLINE*
                  </b-checkbox>
                </b>
              </b-card>
            </div>
            <div class="col-12 col-lg-6">
              <b-card>
                <h4><b-icon-tag /> Configuração de Senhas</h4>
                <b-form-group>
                  <label>Senha Buscar</label>
                  <div class="row">
                    <div class="col-6">
                      <label>Minimo</label>
                      <b-form-input
                        v-mask="'###'"
                        type="number"
                        v-model="senha_buscar[0]"
                        :min="0"
                        :max="999"
                      />
                    </div>
                    <div class="col-6">
                      <label>Máximo</label>
                      <b-form-input
                        v-mask="'###'"
                        type="number"
                        v-model="senha_buscar[1]"
                        :min="0"
                        :max="999"
                      />
                    </div>
                  </div>
                </b-form-group>

                <b-form-group>
                  <label>Senha Balcão</label>
                  <div class="row">
                    <div class="col-6">
                      <label>Minimo</label>
                      <b-form-input
                        v-mask="'###'"
                        type="number"
                        v-model="senha_balcao[0]"
                        :min="0"
                        :max="999"
                      />
                    </div>
                    <div class="col-6">
                      <label>Máximo</label>
                      <b-form-input
                        v-mask="'###'"
                        type="number"
                        v-model="senha_balcao[1]"
                        :min="0"
                        :max="999"
                      />
                    </div>
                  </div>
                </b-form-group>

                <b-form-group>
                  <label>Senha Entrega</label>
                  <div class="row">
                    <div class="col-6">
                      <label>Minimo</label>
                      <b-form-input
                        v-mask="'###'"
                        type="number"
                        v-model="senha_entrega[0]"
                        :min="0"
                        :max="999"
                      />
                    </div>
                    <div class="col-6">
                      <label>Máximo</label>
                      <b-form-input
                        v-mask="'###'"
                        type="number"
                        v-model="senha_entrega[1]"
                        :min="0"
                        :max="999"
                      />
                    </div>
                  </div>
                </b-form-group>

                <b-form-group>
                  <label>Senha Mesa</label>

                  <div class="row">
                    <div class="col-6">
                      <label>Minimo</label>
                      <b-form-input
                        v-mask="'###'"
                        type="number"
                        v-model="senha_mesa[0]"
                        :min="0"
                        :max="999"
                      />
                    </div>
                    <div class="col-6">
                      <label>Máximo</label>
                      <b-form-input
                        v-mask="'###'"
                        type="number"
                        v-model="senha_mesa[1]"
                        :min="0"
                        :max="999"
                      />
                    </div>
                  </div>
                </b-form-group>
              </b-card>
            </div>
            <div class="col-12 col-sm-6">
              <b-card class="mt-2">
                <b-card-title>
                  <i class="fa fa-cogs"></i> Configuracões de Pedidos
                </b-card-title>
                <b-check
                  v-model="empresa.configs.pedidos.agendamento"
                  switch
                  >Solicitar Agendamento</b-check
                >
                <b-check
                  v-model="empresa.configs.pedidos.tiposEntrega"
                  switch
                  >Solicitar Tipos de Entrega</b-check
                >
                <b-check
                  v-model="empresa.configs.pedidos.solicitarCliente"
                  switch
                  >Solicitar Cliente</b-check
                >
                <b-check
                  v-model="empresa.configs.pedidos.addProdRapido"
                  switch
                  >Adicionar Produto Por Busca</b-check
                >
                <b-check
                  v-model="empresa.configs.pedidos.selecionarCliente"
                  switch
                  >Selecionar Cliente do Cadastro</b-check
                >
                <b-select
                  v-model="empresa.configs.pedidos.tipoEntregaPadrao"
                  :options="['BALCÃO', 'BUSCAR', 'ENTREGA']"
                ></b-select>
              </b-card>
            </div>
          </div>
        </b-tab>
        <b-tab>
          <template #title> <b-icon-app-indicator /> Salão/ Mesas </template>
          <b-row class="mt-">
            <b-col cols="12">
              <b-card>
                <b-card-title>
                  <i class="fa fa-cogs mr-1" /> Geral
                </b-card-title>
                <b-card-text>
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b>Exibição de Mesas / Comandas</b>
                      <b-select
                        v-model="empresa.configs.exibeMesas"
                        :options="['Somente do dia', 'Todas em aberto']"
                      />
                    </b-col>

                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-checkbox
                        switch
                        v-model="empresa.configs.imprimeSenhaValePorPedido"
                        :value="true"
                        :unchecked-value="false"
                      >
                        Imprimir Senha/Vale por Pedido
                      </b-checkbox>
                      <b-checkbox
                        switch
                        v-model="empresa.configs.solicitarClienteComanda"
                        :value="true"
                        :unchecked-value="false"
                      >
                        Solicitar Cliente na Comanda
                      </b-checkbox>
                      <b-checkbox
                        switch
                        v-model="empresa.configs.travarAberturaComandaCliente"
                        :value="true"
                        :unchecked-value="false"
                      >
                        Travar Abertura de Comanda por Cliente
                      </b-checkbox>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              class="pt-0"
            >
              <b-card class="mt-2">
                <h4><b-icon-arrow-repeat /> Configuração de Rodízio</h4>

                <hr />
                <div class="table-responsive">
                  <table
                    class="table table-striped table-sm"
                    style="font-size: 10px"
                  >
                    <tr>
                      <th
                        v-for="(d, idx) in empresa.configs.rodizioConfig"
                        :key="idx"
                        style="border-right: 1px solid #ccc"
                      >
                        <b-check
                          switch
                          v-model="d.lancaRodizio"
                          >{{ d.dayWeek }}</b-check
                        >
                      </th>
                    </tr>
                    <tr>
                      <th
                        v-for="(d, idx) in empresa.configs.rodizioConfig"
                        :key="idx"
                        style="border-right: 1px solid #ccc"
                      >
                        <label>Completo</label>
                        <v-select
                          :options="produtos"
                          v-model="d.rodizioCompleto"
                          @change="force"
                          @click="force"
                          @input="force"
                          label="nome"
                          :reduce="(sel) => sel.cod_produto"
                          style="font-size: 9px"
                          multiple
                        ></v-select>
                        <label>Meio</label>
                        <v-select
                          :options="produtos"
                          v-model="d.rodizioMeio"
                          label="nome"
                          @change="force"
                          @click="force"
                          style="font-size: 9px"
                          @input="force"
                          :reduce="(sel) => sel.cod_produto"
                          multiple
                        ></v-select>
                      </th>
                    </tr>

                    <!-- <template v-for="d in empresa.configs.rodizioConfig">
                    <tr
                      class="bg-light"
                      :key="`tr_rod${d.day}`"
                    >
                      <td style="width: 50%">{{ d.dayWeek }}</td>
                      <td style="width: 50%">
                        <b-check
                          switch
                          v-model="d.lancaRodizio"
                          >Lançar Rodízio?</b-check
                        >
                      </td>
                    </tr>
                    <tr :key="`tr2_rod${d.day}`">
                      <td>
                        <label>Completo</label>
                        <v-select
                          :options="produtos"
                          v-model="d.rodizioCompleto"
                          @change="force"
                          @click="force"
                          @input="force"
                          label="nome"
                          :reduce="(sel) => sel.cod_produto"
                          style="font-size: 9px"
                          multiple
                        ></v-select>
                      </td>
                      <td>
                        <label>Meio</label>
                        <v-select
                          :options="produtos"
                          v-model="d.rodizioMeio"
                          label="nome"
                          @change="force"
                          @click="force"
                          style="font-size: 9px"
                          @input="force"
                          :reduce="(sel) => sel.cod_produto"
                          multiple
                        ></v-select>
                      </td>
                    </tr>
                  </template> -->
                  </table>
                </div>
              </b-card>
            </b-col>
            <b-col cols="12">
              <b-card class="mt-2">
                <h4><i class="fa fa-guitar mr-1" /> Configuração de Couvert</h4>

                <hr />

                <table
                  class="table table-striped table-sm"
                  style="font-size: 10px"
                >
                  <tr>
                    <th
                      v-for="(d, idx) in empresa.configs.couvertConfig"
                      :key="idx"
                      style="border-right: 1px solid #ccc"
                    >
                      <b-check
                        switch
                        v-model="d.lancaCouvert"
                        >{{ d.dayWeek }}</b-check
                      >
                    </th>
                  </tr>
                  <tr>
                    <th
                      v-for="(d, idx) in empresa.configs.couvertConfig"
                      :key="idx"
                      style="border-right: 1px solid #ccc"
                    >
                      <label>Completo</label>
                      <v-select
                        :options="produtos"
                        v-model="d.couvertInteira"
                        @change="force"
                        @click="force"
                        @input="force"
                        label="nome"
                        :reduce="(sel) => sel.cod_produto"
                        style="font-size: 9px"
                        multiple
                      ></v-select>
                      <label>Meio</label>
                      <v-select
                        :options="produtos"
                        v-model="d.couvertMeia"
                        label="nome"
                        @change="force"
                        @click="force"
                        style="font-size: 9px"
                        @input="force"
                        :reduce="(sel) => sel.cod_produto"
                        multiple
                      ></v-select>
                    </th>
                  </tr>
                </table> </b-card
            ></b-col>
          </b-row>
        </b-tab>

        <b-tab>
          <template #title> <i class="fa fa-boxes" /> Estoque </template>

          <b-row class="mt-3">
            <b-col
              cols="12"
              lg="6"
            >
              <b-card>
                <h4><i class="fa fa-boxes" /> Gerenciamento de Estoque</h4>

                <Estoque
                  :listaRec="empresa.estoque"
                  :cod_empresa="empresa.cod_empresa"
                  @change="(ev) => (empresa.estoque = ev)"
                ></Estoque>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <b-card>
                <h4><b-icon-box /> Grupos de Produtos</h4>
                <b-table
                  :items="empresa.cardapio"
                  :fields="[
                    { key: 'Grupo', label: 'Grupo' },
                    { key: 'ativo', label: 'Visível' },
                  ]"
                >
                  <template #cell(ativo)="row">
                    <b-checkbox
                      switch
                      v-model="row.item.ativo"
                    ></b-checkbox>
                  </template>
                </b-table>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab>
          <template #title><i class="fa fa-cash-register" /> Caixas</template>
          <b-card>
            <b>Tipo de Caixa</b>
            <b-select
              :options="['Conferencia', 'Simples']"
              v-model="empresa.configs.tipoCaixas"
            ></b-select>
          </b-card>
          <caixas-manutencao
            :lista="empresa.caixas"
            :codEmpresa="empresa.cod_empresa"
            @change="(ev) => (empresa.caixas = ev)"
          />
        </b-tab>
        <b-tab>
          <template #title> <i class="fa fa-dollar" /> Fiscal </template>
          <empresa-fiscal
            :empresa="empresa"
            @changed="($event) => (empresa = $event)"
          ></empresa-fiscal>
        </b-tab>
        <b-tab v-if="empresa && empresa.integracoes">
          <template #title> <i class="fas fa-plug" /> Integrações </template>
          <Integracoes
            :lista="empresa.integracoes"
            @change="(ev) => (empresa.integracoes = ev)"
          />
        </b-tab>

        <b-tab v-if="empresa && empresa.configs && empresa.configs.apps">
          <template #title>
            <i class="fas fa-mobile-alt" /> Aplicativos
          </template>

          <Aplicativos
            :dados="empresa.configs.apps"
            @change="(ev) => (empresa.configs.apps = ev)"
          ></Aplicativos>
        </b-tab>

        <b-tab>
          <template #title> <i class="fas fa-map" /> Endereços </template>
          <Endereco :enderecos="empresa.enderecos ? empresa.enderecos : []" />
          <b-input
            hidden
            :state="validateState('telefones')"
          />
          <b-form-invalid-feedback>
            Informe ao menos um endereço
          </b-form-invalid-feedback>
        </b-tab>

        <b-tab>
          <template #title> <i class="fas fa-phone-alt" /> Telefones </template>
          <Telefones :telefones="empresa.telefones ? empresa.telefones : []" />
          <b-input
            hidden
            :state="validateState('telefones')"
          />
          <b-form-invalid-feedback>
            Informe ao menos um telefone
          </b-form-invalid-feedback>
        </b-tab>
        <b-tab v-if="empresa && empresa.configs && empresa.configs.whatsapp">
          <template #title> <i class="fab fa-whatsapp" /> Whatsapp </template>

          <empresa-whatsapp
            :whatsapp="empresa.configs.whatsapp"
            @update:whatsapp="(ev) => (empresa.configs.whatsapp = ev)"
          ></empresa-whatsapp>
        </b-tab>
      </b-tabs>

      <div
        class="bottom-actions"
        style="position: unset"
      >
        <hr class="mb-2" />
        <b-btn
          variant="primary"
          class="mr-3"
          @click="salvar()"
        >
          <i
            class="fas fa-save"
            v-if="!loading"
          />
          <b-spinner
            small
            v-if="loading"
          />
          Salvar
        </b-btn>
        <b-btn
          variant="danger"
          @click="fecharModal()"
        >
          <i
            class="fas fa-ban"
            v-if="!loading"
          />
          <b-spinner
            small
            v-if="loading"
          />
          Cancelar
        </b-btn>
      </div>
    </b-form>
  </div>
</template>

<script>
import EmpresasLib from "../../libs/EmpresasLib";
import CentroCustosLib from "../../libs/CentrosDeCustoLib";

// import Validadores from "@/helpers/Validadores";
import CaixasManutencao from "../Caixas/CaixasManutencao.vue";
import Endereco from "../Enderecos/Endereco";
import Telefones from "../Telefones/Telefones";
import SendFiles from "../common/SendFiles";
import { validationMixin } from "vuelidate";

// import FileUpload from "../common/FileUpload.vue";
import EmpresaWhatsapp from "./EmpresaWhatsapp.vue";
import {
  required,
  // minLength,
  // maxValue,
  // minValue,
} from "vuelidate/lib/validators";
import Integracoes from "./Integracoes.vue";
import Estoque from "./Estoque.vue";
import ProdutoLib from "../../libs/ProdutoLib";
import Aplicativos from "./Aplicativos.vue";
import SelectCentroCusto from "../Financeiro/SelectCentroCusto.vue";
import EmpresaFiscal from "./EmpresaFiscal.vue";
// import { BIconTelephoneMinus } from "bootstrap-vue";

let emissaoAutomaticaPadrao = [
  {
    ativo: false,
    tipoVenda: "Balcão",
    limite: 0,
    periodoDe: 1,
    periodoAte: 31,
    formasPagamento: [],
  },
  {
    ativo: false,
    tipoVenda: "Buscar",
    limite: 0,
    periodoDe: 1,
    periodoAte: 31,
    formasPagamento: [],
  },
  {
    ativo: false,
    tipoVenda: "Entrega",
    limite: 0,
    periodoDe: 1,
    periodoAte: 31,
    formasPagamento: [],
  },
  {
    ativo: false,
    tipoVenda: "Mesa",
    limite: 0,
    periodoDe: 1,
    periodoAte: 31,
    formasPagamento: [],
  },
];

export default {
  mixins: [validationMixin],
  components: {
    Endereco,
    Telefones,
    SendFiles,
    CaixasManutencao,
    Integracoes,
    Estoque,
    Aplicativos,
    SelectCentroCusto,
    // FileUpload,
    EmpresaWhatsapp,
    EmpresaFiscal,
  },
  props: {
    onSaveDone: Function,
    empresa_edit: Object,
  },
  data() {
    return {
      formas_pagamento: [
        "PIX",
        "Cartão",
        "Dinheiro",
        "Vale",
        "Dim. + Cart.",
        "Online",
      ],
      custos: [],
      produtos: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: true,
      },
      empresa: {
        razao_social: "",
        fantasia: "",
        nome: "",
        cnpj: "",
        enderecos: [],
        telefones: [],
        email: "",
        logo: "",
        cod_empresa_pai: null,
        configuracoes: {
          frete_padrao: 0,
          frete_pg_padrao: 0,
          frete_online: 0,
          frete_pg_online: 0,
          taxa_servico: 0,
          senha_buscar: null,
          senha_balcao: null,
          senha_entrega: null,
          desconta_estoque: 0,
          centro_custo_id: null,
          app_ativo: 0,
          app_aberto_de: null,
          app_aberto_ate: null,
        },
        fiscal: {
          notaSaida: {
            tipoEmissao: "Local",
            CFOP: "",
            naturezaOperacao: "",
            emissaoAutomatica: emissaoAutomaticaPadrao,
            razao_social: "",
            fantasia: "",
            cnpj: "",
            ie: "",
            im: "",
            emissaoAutomaticaOnline: false,
            emissaoAutomaticaOnlineFormasPagamento: [],
            emissaoAutomaticaOnlineProduto: null,
            endereco: {
              logradouro: "",
              numero: "",
              bairro: "",
              cidade: "",
              uf: "",
              cep: "",
              complemento: "",
              fone: "",
              cidadeIBGE: "",
            },
            certificado: {
              senha: "",
              arquivo: [],
            },
            ambiente: "2",
            configAmbiente: {
              homologacao: {
                idCSC: "1",
                CSC: "",
                serie: "",
                ultimaNFCe: 0,
              },
              producao: {
                idCSC: "1",
                CSC: "",
                serie: "",
                ultimaNFCe: 0,
              },
            },
          },
        },
        integracoes: [],
        estoque: [],
        cardapio: this.$store.state.cardapio.map((it) => ({
          Grupo: it.Grupo,
          ativo: true,
          cod_grupo: it.cod_grupo,
        })),
        configs: {},
      },
      senha_buscar: [0, 0],
      senha_balcao: [0, 0],
      senha_entrega: [0, 0],
      senha_mesa: [0, 0],
      hasErrors: false,
      loading: false,
      editando: false,
      caixas: [],
    };
  },
  mounted() {
    // this.clearErrors();

    // console.log("card", this.$store.state.cardapio)
    Object.assign(this.empresa, this.empresa_edit);

    this.carregarCentroCustos();
    this.carregaProdutos();

    // if (this.empresa_edit.cod_empresa)
    {
      if (!this.empresa_edit.fiscal) {
        this.empresa_edit.fiscal = {
          notaSaida: {
            CFOP: "",
            naturezaOperacao: "",
            emissaoAutomatica: emissaoAutomaticaPadrao,
          },
        };
      }
      if (!this.empresa_edit.fiscal.notaSaida.emissaoAutomatica) {
        console.log("setting emiss fisc");
        this.empresa_edit.fiscal.notaSaida.emissaoAutomatica =
          emissaoAutomaticaPadrao;
      } else {
        for (let tp of this.empresa_edit.fiscal.notaSaida.emissaoAutomatica) {
          tp.limite = this.formatValorReal(tp.limite);
          tp.limite_diario = this.formatValorReal(tp.limite_diario);
          console.log("formating tp", tp);
        }
      }
      if (!this.empresa_edit.integracoes) {
        this.empresa_edit.integracoes = [];
      }
      if (!this.empresa_edit.estoque) {
        this.empresa_edit.estoque = [];
      }
      if (!this.empresa_edit.configs) {
        this.empresa_edit.configs = {};
      }

      if (!this.empresa_edit.configs.dashboard) {
        this.empresa_edit.configs.dashboard = {
          tiposStatusPedidosResumo: [1, 2, 3, 4, -1],
        };
      }
      if (
        typeof this.empresa_edit.configs.dashboard.porFormaPagamento ==
          "undefined" &&
        this.empresa_edit.configs.dashboard.porFormaPagamento === null
      ) {
        this.empresa_edit.configs.dashboard.porFormaPagamento = false;
      }
      if (
        typeof this.empresa_edit.configs.tipoCaixas == "undefined" &&
        this.empresa_edit.configs.tipoCaixas === null
      ) {
        this.empresa_edit.configs.tipoCaixas = false;
      }
      if (!this.empresa_edit.configs.exibeMesas) {
        this.empresa_edit.configs.exibeMesas = "Somente do dia";
      }
      if (!this.empresa_edit.configs.imprimeSenhaValePorPedido) {
        this.empresa_edit.configs.imprimeSenhaValePorPedido = false;
      }
      if (!this.empresa_edit.configs.solicitarClienteComanda) {
        this.empresa_edit.configs.solicitarClienteComanda = false;
      }
      if (!this.empresa_edit.configs.travarAberturaComandaCliente) {
        this.empresa_edit.configs.travarAberturaComandaCliente = false;
      }
      if (!this.empresa_edit.configs.modoSistema) {
        this.empresa_edit.configs.modoSistema = 1;
      }
      if (!this.empresa_edit.configs.pedidos) {
        this.empresa_edit.configs.pedidos = {
          agendamento: false,
          tiposEntrega: false,
          solicitarCliente: false,
          addProdRapido: false,
          tipoEntregaPadrao: "BALCÃO",
          selecionarCliente: false,
        };
      }
      if (!this.empresa_edit.configs.pedidos.agendamento) {
        this.empresa_edit.configs.pedidos.agendamento = false;
      }
      if (!this.empresa_edit.configs.pedidos.tiposEntrega) {
        this.empresa_edit.configs.pedidos.tiposEntrega = false;
      }
      if (!this.empresa_edit.configs.pedidos.solicitarCliente) {
        this.empresa_edit.configs.pedidos.solicitarCliente = false;
      }
      if (!this.empresa_edit.configs.pedidos.addProdRapido) {
        this.empresa_edit.configs.pedidos.addProdRapido = false;
      }
      if (!this.empresa_edit.configs.pedidos.selecionarCliente) {
        this.empresa_edit.configs.pedidos.selecionarCliente = false;
      }
      if (!this.empresa_edit.configs.pedidos.tipoEntregaPadrao) {
        this.empresa_edit.configs.pedidos.tipoEntregaPadrao = "BALCÃO";
      }
      console.log("this.empresa_edit.configs", this.empresa_edit.configs);
      if (
        !this.empresa_edit.configs.rodizioConfig ||
        typeof this.empresa_edit.configs.rodizioConfig == "undefined" ||
        this.empresa_edit.configs.rodizioConfig.length <= 0
      ) {
        this.empresa_edit.configs.rodizioConfig = [0, 1, 2, 3, 4, 5, 6].map(
          (d) => ({
            day: d,
            dayWeek: [
              "Domingo",
              "Segunda",
              "Terça-feira",
              "Quarta-feira",
              "Quinta-feira",
              "Sexta",
              "Sábado",
            ][d],
            lancaRodizio: false,
            rodizioCompleto: null,
            rodizioMeio: null,
          })
        );
      }
      if (
        !this.empresa_edit.configs.couvertConfig ||
        typeof this.empresa_edit.configs.couvertConfig == "undefined" ||
        this.empresa_edit.configs.couvertConfig.length <= 0
      ) {
        this.empresa_edit.configs.couvertConfig = [0, 1, 2, 3, 4, 5, 6].map(
          (d) => ({
            day: d,
            dayWeek: [
              "Domingo",
              "Segunda",
              "Terça-feira",
              "Quarta-feira",
              "Quinta-feira",
              "Sexta",
              "Sábado",
            ][d],
            lancarCouvert: false,
            couvertInteira: null,
            couvertMeia: null,
          })
        );
      }
      if (typeof this.empresa_edit.configs.apps == "undefined") {
        this.empresa_edit.configs.apps = [];
      }
      if (typeof this.empresa_edit.configs.lancaRodizio == "undefined") {
        this.empresa_edit.configs.lancaRodizio = false;
      }
      console.log("cardap", this.$store.state.cardapio);
      if (!this.empresa_edit.cardapio) {
        this.empresa_edit.cardapio = this.$store.state.cardapio.map((it) => ({
          Grupo: it.Grupo,
          ativo: true,
          cod_grupo: it.cod_grupo,
        }));
      } else {
        this.empresa_edit.cardapio = this.$store.state.cardapio.map((it) => ({
          Grupo: it.Grupo,
          cod_grupo: it.cod_grupo,
          ativo:
            this.empresa_edit.cardapio.find((x) => x.Grupo == it.Grupo)
              ?.ativo || false,
        }));
      }
      console.log(this.empresa_edit);

      if (!this.empresa_edit.configs.whatsapp) {
        this.empresa_edit.configs.whatsapp = {
          ativo: false,
          token: "",
          qrCode: "",
          status: "offline",
          lastUpdate: null,
          fiscal: {
            ativo: false,
            enviarAutomatico: false,
            enviarPDF: false,
            enviarXML: false,
            enviarCopiaNumero: "",
          },
        };
      }

      Object.assign(this.empresa, this.empresa_edit);

      console.log("emp", this.empresa);

      // this.empresa = this.empresa_edit;
      this.editando = true;
    }
    this.formataSenhas();
  },
  validations: {
    empresa: {
      razao_social: { required },
      nome: { required },
      cnpj: {},
      enderecos: {
        // minLength: minLength(1),
      },
      telefones: {
        // minLength: minLength(1),
      },
      configuracoes: {
        frete_padrao: { required },
        frete_pg_padrao: { required },
        frete_online: { required },
        frete_pg_online: { required },
        taxa_servico: { required },
        // senha_balcao: {
        //   required,
        //   maxValue: maxValue(999),
        //   minValue: minValue(0),
        // },
        // senha_buscar: {
        //   required,
        //   maxValue: maxValue(999),
        //   minValue: minValue(0),
        // },
        // senha_entrega: {
        //   required,
        //   maxValue: maxValue(999),
        //   minValue: minValue(0),
        // },
      },
    },
  },
  methods: {
    force() {
      this.$nextTick();
      this.$forceUpdate();
    },
    async carregaProdutos() {
      console.log("carregando produtos");
      this.produtos = await ProdutoLib.get(null, null, true);
      console.log("carregando produtos OK", this.produtos);
    },
    async carregarCentroCustos() {
      this.custos = (await CentroCustosLib.get()).map((it) => {
        return {
          text: it.nome,
          id: it.cod_custo,
        };
      });
      console.log("-.>", this.custos);
    },
    setNewFile(logo) {
      // console.log('novo logo',logo)
      this.empresa.logo = logo;
    },
    fecharModal() {
      this.$bvModal.hide("modal-empresa");
    },
    async formataSenhas() {
      await this.$nextTick();
      this.senha_buscar = this.empresa.configuracoes.senha_buscar.split("|");

      this.senha_balcao = this.empresa.configuracoes.senha_balcao.split("|");

      this.senha_entrega = this.empresa.configuracoes.senha_entrega.split("|");

      this.senha_mesa = this.empresa.configuracoes.senha_mesa.split("|");
    },
    async editar(empresa) {
      this.empresa = empresa;
    },
    validateState(name, child = "") {
      // console.log(name, child, this.$v.empresa[name][child]);
      const { $dirty, $error } =
        child === "" ? this.$v.empresa[name] : this.$v.empresa[name][child];
      // if ($error) {
      //   if (child) {
      //     child = child.replace("_", " ").split(" ");
      //     child[0] = child[0].charAt(0).toUpperCase() + child[0].slice(1);
      //     child[1] = child[1].charAt(0).toUpperCase() + child[1].slice(1);
      //     child = child.join(" ");
      //     this.hasErrors.push(child)
      //   } else {
      //     name = name.replace("_", " ").split(" ");
      //     name[0] = name[0].charAt(0).toUpperCase() + name[0].slice(1);
      //     name[1] = name[1].charAt(0).toUpperCase() + name[1].slice(1);
      //     name = name.join(" ");
      //      this.hasErrors.push(name)
      //   }
      // }
      return $dirty ? !$error : null;
    },

    showErrorSave(error = "") {
      this.$swal.fire({
        title: `Erro ao salvar! \n${error}`,
        icon: "error",
        toast: true,
        position: "top-end",
        timer: 1500,
        showConfirmButton: false,
        timerProgressBar: true,
      });
    },
    async salvar() {
      this.$v.empresa.$touch();
      this.hasErrors = false;
      // console.log("validou?", this.$v.empresa.$params);
      if (this.$v.empresa.$anyError) {
        // this.showErrorSave(this.hasErrors[0])
        for (const key in this.empresa.configuracoes) {
          if (this.empresa.configuracoes[key] === "") {
            console.log("error", this.empresa.configuracoes[key]);
            this.showErrorSave("Configurações inválidas!");
            this.hasErrors = true;
            break;
          } else {
            this.showErrorSave("Informações inválidas!");
          }
        }
        return;
      }
      await this.$nextTick();
      try {
        this.loading = true;
        console.log("config", this.empresa.configuracoes);
        this.empresa.configuracoes.senha_buscar = this.senha_buscar.join("|");
        this.empresa.configuracoes.senha_balcao = this.senha_balcao.join("|");
        this.empresa.configuracoes.senha_entrega = this.senha_entrega.join("|");
        this.empresa.configuracoes.senha_mesa = this.senha_mesa.join("|");

        /*this.empresa.configuracoes.taxa_servico = parseFloat(
          this.empresa.configuracoes.taxa_servico
            .replace("R$", "")
            .replace(".", "")
            .replace(",", ".")
        );
        this.empresa.configuracoes.frete_padrao = parseFloat(
          this.empresa.configuracoes.frete_padrao
            .replace("R$", "")
            .replace(".", "")
            .replace(",", ".")
        );
        this.empresa.configuracoes.frete_pg_padrao = parseFloat(
          this.empresa.configuracoes.frete_pg_padrao
            .replace("R$", "")
            .replace(".", "")
            .replace(",", ".")
        );
        this.empresa.configuracoes.frete_online = parseFloat(
          this.empresa.configuracoes.frete_online
            .replace("R$", "")
            .replace(".", "")
            .replace(",", ".")
        );
        this.empresa.configuracoes.frete_pg_online = parseFloat(
          this.empresa.configuracoes.frete_pg_online
            .replace("R$", "")
            .replace(".", "")
            .replace(",", ".")
        );*/

        // console.log(this.empresa.configuracoes.taxa_servico);
        // if (this.$refs.fileComponent.file) {
        //   this.empresa.logo = this.$refs.file.file;
        // }
        // const validado = await this.validar();
        // await this.$refs.fileComponent.uploadFile();

        // console.log("logo da empresa ", this.empresa.logo);

        console.log(this.empresa);

        const res = await EmpresasLib.store(this.empresa);
        // console.log(res);
        if (res && res.success) {
          this.$swal.fire({
            title: "Concluído!",
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 1500,
            showConfirmButton: false,
            timerProgressBar: true,
          });

          this.$bvModal.hide("modal-empresa");

          this.$emit("salvou");
          window.location.reload();
        }
      } catch (e) {
        this.showErrorSave(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/default.scss";

.vue-slider-dot-handle {
  background-color: $primary;
}

.vue-slider-process {
  background-color: $secondary;
}

.vue-slider-dot-tooltip-inner {
  background-color: $bgMenu;
  border-color: $bgMenu;
}

.vue-slider .vue-slider-ltr {
  padding: 10px !important;
}
</style>
