<template>
  <div :class="onModal ? '' : 'container-fluid'">
    <barra-crud
      :botaoNovo="true"
      :onFiltrar="
        (txt) => {
          filtro = txt;
        }
      "
      :breadcrumb="false"
      :busca-aberta="true"
      :onNovo="() => openCliente(null)"
      size-bt="lg"
      :show-title="!onModal"
      :nome-customizado="'Cliente'"
    />
    <div
      class="text-center p-5"
      v-if="loading"
    >
      <b-spinner
        large
        size="lg"
      /><br />
      carregando...
    </div>
    <div
      class="row"
      v-if="!loading"
    >
      <div
        class="col-12 text-left"
        :class="onModal ? '' : 'p-lg-5'"
      >
        <b-table
          :items="lista"
          :current-page="currentPage"
          :per-page="perPage"
          hover
          select-mode="single"
          ref="tbLista"
          selectable
          :bordered="false"
          :filter="filtro"
          stacked="sm"
          @row-selected="
            (its) =>
              its && its.length > 0
                ? openCliente(its[0].cod_cliente, onModal)
                : openCliente(null, true)
          "
          :fields="[
            { key: 'cod_cliente', label: '', searchable: true },
            { key: 'Nome', label: 'Nome', sortable: true, class: 'w-50' },
            { key: 'Telefone', label: 'Telefone', sortable: true },
            {
              key: 'saldo',
              label: 'Saldo',
              sortable: true,
              class: 'text-center ' + (onModal ? 'd-none' : ''),
            },
            { key: 'situacao', label: 'Situação' },
            {
              key: 'situacao_acesso',
              label: 'Acesso',
              class: onModal ? 'd-none' : '',
            },
            {
              key: 'opc',
              label: '',
              class: onModal ? '' : 'd-none',
            },
          ]"
        >
          <template #cell(cod_cliente)="row">
            <b-avatar
              size="lg"
              :src="
                !row.item.foto || row.item.foto.length <= 0
                  ? `https://ui-avatars.com/api/?name=${row.item.Nome}&size=128&background=777&color=fff`
                  : row.item.foto[0].fileURL
              "
            ></b-avatar>
          </template>
          <template #cell(saldo)="row">
            {{ row.item.saldo | currency }}
          </template>
          <template #cell(opc)="row">
            <div class="d-flex">
              <b-btn
                size="sm"
                variant="success"
                ref="btSel"
                @click="selectCliente(row.item)"
                v-if="onModal"
                >Selecionar</b-btn
              >

              <b-button
                size="sm"
                variant="primary"
                @click.stop="openCliente(row.item.cod_cliente, false)"
                >Editar</b-button
              >
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
      <div
        v-if="editId && onModal"
        class="p-5 mx-5 w-100"
      >
        <b-btn
          block
          size="lg"
          variant="success"
          ref="btSel"
          @click="selectCliente(null)"
          >SELECIONAR CLIENTE</b-btn
        >
      </div>
    </div>
    <b-modal
      id="modal-form-cliente"
      hide-footer
      size="md"
      :content-class="'prodModal'"
      body-class="insideModal p-0 pt-2"
      no-fade
      no-footer
      class="prodModal"
      :title="!editId ? 'Cadastrando Cliente' : 'Editando Cliente'"
      @hidden="closeForm"
    >
      <cliente-completo
        :id="editId"
        @close="closeForm"
        @closeAndOpen="closeFormSel"
      ></cliente-completo>
    </b-modal>
  </div>
</template>

<script>
import ClienteCompleto from "../../../components/clientes/ClienteCompleto.vue";
import BarraCrud from "../../../components/common/BarraCrud.vue";
import ClientesLib from "../../../libs/ClientesLib";
export default {
  components: {
    BarraCrud,
    ClienteCompleto,
  },
  props: {
    onModal: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      loading: false,
      lista: [],
      totalRows: 1,
      currentPage: 1,
      perPage: this.onModal ? 5 : 15,
      filtro: "",
      editId: null,
    };
  },
  watch: {
    editId(val) {
      if (val) {
        this.$refs.btSel.focus();
      }
    },
  },
  computed: {},
  methods: {
    selectCliente(hasClient = null) {
      console.log("ok", hasClient);
      if (hasClient) {
        this.editId = hasClient.cod_cliente;
        this.$emit("selectCliente", hasClient.cod_cliente);
        this.closeForm();
      } else {
        this.$emit("selectCliente", this.editId);
        this.closeForm();
      }
    },
    async closeFormSel(idSel) {
      console.log("idSel===>", idSel);
      if (idSel && this.onModal) {
        await this.openCliente(idSel);
        this.selectCliente();
        return;
      }
    },
    async closeForm() {
      this.$bvModal.hide("modal-form-cliente");
      if(this.$refs.tbLista && this.$refs.tbLista.clearSelected)
        this.$refs.tbLista.clearSelected();
      this.carregar();
    },
    openCliente(id, tab = false) {
      console.log("open cliente", id, tab);
      this.editId = id;
      if (this.onModal && id && tab) {
        this.editId = id;
        // this.$emit("selectCliente", id);
      } else if (!tab) {
        this.$bvModal.show("modal-form-cliente");
      }
    },
    async carregar() {
      this.loading = true;

      try {
        let res = await ClientesLib.getAllClientes(null);
        console.log("res clientes", res);
        if (res && res.success) {
          this.lista = res.data;
          this.totalRows = this.lista.length;
          this.currentPage = 1;
        }
      } catch (error) {
        console.log("ERROR ON GET CLIENTES", error);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
