<template>
  <div style="width: 100%; height: 100%; color: #555">
    <div
      style="overflow-x: hidden"
      class="containerPedidos scrollPersonalizadoWH"
      v-if="tipoVisu == 'Pedidos'"
    >
   
      <div
        class="d-flex justify-content-between mb-2"
        v-if="!fechado && !isMobile"
      >
        <div class="d-flex justify-content-start flex-column flex-md-row mb-2">
          <b-btn
            :size="isMobile ? 'lg' : ''"
            variant="info"
            class="mr-1 mt-2 text-lg-left text-center"
            @click="addPedido"
          >
            <i class="fa fa-shopping-cart"></i> Adicionar Item
          </b-btn>

          <b-btn
            v-if="showButtons"
            :size="isMobile ? 'lg' : ''"
            variant="danger"
            class="mr-1 mt-2 text-lg-left text-center"
            @click="removeTaxa"
            ><b-icon-percent />
            <span> Alterar Taxa</span>
          </b-btn>
          <b-btn
            v-if="showButtons"
            :size="isMobile ? 'lg' : ''"
            variant="success"
            class="mr-1 mt-2 text-lg-left text-center"
            @click="addPgto"
            ><b-icon-currency-dollar />
            <span v-if="!isMobile"> Adicionar Pagamento</span>
          </b-btn>
        </div>
        <b-btn
          :size="isMobile ? 'lg' : ''"
          variant="primary"
          class="mr-1 mt-2 text-lg-left text-center"
          @click="fecharMesa"
        >
          <i class="fas fa-clipboard-check"></i> Fechar
          {{ comandaSel ? "Comanda" : "Mesa" }}
        </b-btn>
      </div>
      <div v-if="fechado && !isMobile" class="d-flex justify-content-end mb-2">
        <b-btn
          :size="isMobile ? 'lg' : ''"
          variant="warning"
          class="mr-1 mt-2 text-lg-left text-center"
          @click="reabrirMesa"
        >
            <i class="fas fa-folder-open"></i> Reabrir
          {{ comandaSel ? "Comanda" : "Mesa" }}
        </b-btn>
      </div>

      <div class="row">
        <div class="col-12 col-md-7 pr-0 pr-sm-1 pl-0 pl-sm-3">
          <div
            v-if="!pedidosPorPedido || pedidosPorPedido.length <= 0"
            class="p-2 text-center"
          >
            Nenhum pedido adicionado ainda.
          </div>

          <div
            v-for="p in pedidosPorPedido"
            :key="`ped_${p.qt}`"
            class="pedido"
            :class="p.itens[0].excluido === 1 ? '' : ''"
          >
            <b-card>
              <b-card-title>
                <div class="d-flex justify-content-between">
                  <div>
                    <i class="fa fa-barcode mr-1" />
                    {{ ` ${getComandaNome(p.comanda, 0) || ""}` }}
                    <br v-if="$store.state.isMobile" />
                    <span
                      v-if="
                        getComandaNome(p.comanda, 1) &&
                        getComandaNome(p.comanda, 1).trim() != ''
                      "
                      class="ml-2"
                      style="font-size: 10px"
                    >
                      <i class="fa fa-user mr-1" />
                      {{ getComandaNome(p.comanda, 1) || "" }}
                      <i class="fa fa-phone ml-2 mr-1" />
                      {{ getComandaNome(p.comanda, 2) || "" }}
                    </span>
                  </div>
                  <div>
                    <b-btn
                      @click="$emit('trocarMesaComanda', p)"
                      v-if="
                        (comandaSel && !comandaSel.dt_fechada) || !comandaSel
                      "
                    >
                      <i class="fas fa-exchange-alt"></i>
                    </b-btn>
                  </div>
                </div>
              </b-card-title>
              <b-card-sub-title
                class="mb-4"
                style="font-size: 12px"
              >
                <b-icon-person
                  class="mr-1"
                  v-if="p.usuario"
                />{{ `${p.usuario || ""} ` }} <b-icon-clock class="mr-1" />
                {{ p.data | moment("DD/MM/YYYY HH:mm") }}
              </b-card-sub-title>
              <div
                v-for="it in p.itens"
                :key="`it_${p.qt}_${it.cnt}`"
                class="item"
                :class="it.excluido === 1 ? 'pedidoCancelado' : ''"
                @click="openItem(it)"
              >
                <div>
                  - {{ it.quantidade }} x {{ it.prod }}
                  {{ it.prod2 && it.prod2 != "" ? " / " + it.prod2 : "" }}
                  {{ it.borda && it.borda != "" ? " - Borda " + it.borda : "" }}
                  <span
                    v-if="it.excluido === 1"
                    style="color: red !important"
                    class="ml-3"
                  >
                    <b-icon-trash /> EXCLUIDO
                  </span>
                  <div
                    style="font-size: 8px"
                    class="pl-4"
                  >
                    <i class="fa fa-headset mr-1" /> {{ it.usuario }}
                    <b-icon-clock class="ml-1" />
                    {{ it.data | moment("DD/MM/YYYY HH:mm") }}
                  </div>
                </div>
                <div
                  class=""
                  style="
                    align-self: self-end;
                    min-width: 60px;
                    text-align: right;
                  "
                >
                  <b-btn
                    v-if="it.prod && it.prod.toUpperCase().indexOf('ROD') >= 0"
                    size="sm"
                    v-b-tooltip.hover
                    title="IMPRIMIR COMPROVANTE SAIDA"
                    class="mr-2"
                    variant="info"
                    @click.stop="imprimeComprovanteSaida(it)"
                  >
                    <b-icon-check />
                  </b-btn>
                  <small class="mr-2"
                    ><small
                      >({{ it.quantidade }} x
                      {{ it.vl_unitario | currency }})</small
                    ></small
                  >
                  {{ it.vl_total | currencyMask }}
                </div>
              </div>
              <div class="mt-4">
                <b
                  >Total
                  <div class="float-right">
                    {{ p.vl | currencyMask }}
                  </div>
                </b>
              </div>
            </b-card>
          </div>

          <div
            :class="`btPlus text-center`"
            @click="addPedido()"
            v-if="
              (comandaSel && !comandaSel.dt_fechada) ||
              (!comandaSel && !mesaObj.dt_fechada)
            "
          >
            <i class="fa fa-plus" />
          </div>
        </div>

        <div
          class="col-12 col-md-5 pl-0 pl-sm-1"
          v-if="!isMobile"
        >
          <mesa-new-pedido-resumo
            :mesaObj="mesaObj"
            :mesaVisu="mesaVisu"
            :getMesaNome="getMesaNome"
            :comanda="comandaSel"
            @addPgto="addPgto"
            @removerTaxa="removeTaxa()"
            :showFechar="(comandaSel && !comandaSel.dt_fechada) || !comandaSel"
            :showButtons="(comandaSel && !comandaSel.dt_fechada) || !comandaSel"
            @fecharMesa="fecharMesa"
            @gotoPG="gotoPG"
            @addItem="addPedido"
            @addPagamento="addPagamento"
            :editPagamento="editPagamento"
            :showPagamentos="true"
            :reabrirMesa="reabrirMesa"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MesaNewPedidoResumo from "./MesaNewPedidoResumo.vue";
export default {
  components: { MesaNewPedidoResumo },
  props: {
    mesaObj: Object,
    mesaVisu: Number,
    getMesaNome: Function,
    addPedido: Function,
    comandaSel: Object,
    addPagamento: Function,
    editPagamento: Function,
    data: [Object, String],
    blocked: Boolean,
    reabrirMesa: Function,
    empresaSel: Object,
  },
  mounted() {
    this.calc();
    // console.log("ms", this.mesaObj);
  },
  data() {
    return {
      // pedidosPorPedido: [],
      tipoVisu: "Pedidos",
      showFechar:
        (this.comandaSel && !this.comandaSel.dt_fechada) || !this.comandaSel,
      showButtons: true,
      isMobile: this.$store.state.isMobile,
    };
  },
  watch: {
    mesaObj: {
      deep: true,
      handler() {
        console.log("change", this.mesaObj);
        this.calc();
        this.$forceUpdate();
      },
    },
    comandaSel: function () {
      // console.log("change1", this.comandaSel);
      this.$forceUpdate();
    },
  },
  computed: {
    fechado(){
      return this.comandaSel?this.comandaSel.dt_fechada:this.mesaObj.dt_fechada;
    },
    pedidos() {
      return this.mesaObj.pedidos
        .filter(
          (x) =>
            (this.mesaVisu ? x.cod_mesa == this.mesaVisu : true) &&
            (this.comandaSel ? x.comanda_id == this.comandaSel.id : true)
        )
        .reduce((ret, vl) => {
          let hasProd = ret.find(
            (x) =>
              x.cod_produto == vl.cod_produto && x.cod_pedido == vl.cod_pedido
          );
          if (!hasProd) {
            ret.push({ ...vl, lst: [vl] });
          } else {
            if (
              !hasProd.lst.some((x) => x.cod_pedido_item == vl.cod_pedido_item)
            ) {
              hasProd.quantidade += vl.quantidade;
              hasProd.vl_total += vl.vl_total;
            }
          }
          return ret;
        }, []);
    },
    pedidosPorPedido() {
      console.log("p", this.mesaObj.pedidos);
      let peds = this.mesaObj.pedidos
        .filter(
          (x) =>
            (this.mesaVisu ? x.cod_mesa == this.mesaVisu : true) &&
            (this.comandaSel ? x.comanda_id == this.comandaSel.id : true)
        )
        .reduce((ret, vl) => {
          let has = ret.find((x) => x.comanda == vl.comanda);
          let it = vl;
          if (!has) {
            ret.push({
              comanda_id: vl.comanda_id,
              comanda: vl.comanda,
              itens: [it],
              qt: ret.length + 1,
              vl: !vl.excluido || vl.excluido == 0 ? vl.vl_total : 0,
              usuario: vl.usuario,
              data: vl.data,
              vl_un: vl.vl_unitario,
              cod_pedido: vl.cod_pedido,
            });
          } else {
            has.itens.push(it);
            if (!vl.excluido || vl.excluido == 0) has.vl += vl.vl_total;
          }
          return ret;
        }, []);

      for (let p of peds) {
        p.itens = p.itens.reduce((ret, vl) => {
          let hasProd = ret.find(
            (x) =>
              x.cod_produto == vl.cod_produto && x.cod_pedido == vl.cod_pedido
          );
          if (!hasProd) {
            ret.push({ ...vl, lst: [vl] });
          } else {
            if (
              !hasProd.lst.some((x) => x.cod_pedido_item == vl.cod_pedido_item)
            )
              hasProd.quantidade += vl.quantidade;
            hasProd.vl_total += vl.vl_total;
          }
          return ret;
        }, []);
      }
      console.log("peds", peds);
      return peds;
    },
  },
  methods: {
    gotoPG() {
      this.$emit("gotoPG");
    },
    getComandaNome(nome, index) {
      if (nome && nome.includes("|")) {
        return nome.split("|")[index];
      } else {
        return nome;
      }
    },
    calc() {
      // this.pedidosPorPedido =
    },
    addPgto() {
      this.$emit("addPgto");
    },
    removeTaxa() {
      console.log("removeTaxa 1");
      this.$emit("removeATaxa");
    },
    imprimeComprovanteSaida(it) {
      console.log("item", it);
      this.$emit("comprovanteSaida", it);

      this.showToast("success", "Impressão de Comprovante de Saida solicitado");
    },
    fecharMesa() {
      if (
        (!this.mesaObj.dt_fechada &&
          this.comandaSel &&
          !this.comandaSel.dt_fechada) ||
        !this.comandaSel
      ) {
        console.log("fechar02");

        this.$emit("fecharMesa");
      }
    },
    openItem(it) {
      
      if (this.comandaSel && this.comandaSel.dt_fechada) return;
      this.$emit("openPedidoEdit", {
        cod_pedido: it.cod_pedido,
        cod_mesa: it.cod_mesa,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btPlus {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: green;
  color: white;
  border-radius: 50%;
  font-size: 50px;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
}
.containerPedidosResumo {
  width: 100%;
  height: 10%;
  padding-top: 5px;
  font-size: 16px;

  text-align: center;
  margin-top: 5px;
  font-weight: bolder;
}
.containerPedidos {
  overflow: hidden;
  overflow-y: auto;
  //width: 100%;
  //height: 100%;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0px;
  bottom: 01px;
  overflow: auto;

  padding-bottom: 40px;

  display: flex;
  flex-direction: column;
  padding: 5px;
  padding-bottom: 200px;
  .itemCancelado {
    opacity: 0.8;
    color: red !important;
    text-decoration: line-through;
  }
  .item {
    font-size: 12px;
    border-bottom: 1px dotted rgb(122, 122, 122);
    padding-bottom: 3px;
    padding-top: 3px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    &:hover {
      background-color: #f8f8f8;
    }
    .imgs {
      background-color: #f9f9f9;
      width: 70px;
      height: 70px;
      display: flex;
      flex-flow: row wrap;
      div {
        flex: 1;
        background-size: cover;
        height: 100%;
        background-repeat: no-repeat;
        background-position: 50%;
        border-right: 1px solid #d9d9d9;
      }
    }
  }
  .pedido {
    margin-bottom: 5px;

    .item {
      font-size: 12px;
      border-bottom: 1px dotted rgb(122, 122, 122);
      padding-bottom: 3px;
      padding-top: 3px;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
        border-left: 2px solid #aaa;
        padding-left: 10px;
      }
    }
  }
  .pedidoCancelado {
    opacity: 0.3;
    color: red;
  }
}
</style>
