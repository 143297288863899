<template>
  <div>
    <h2>Gerador de QRcode</h2>
    <hr />
    <b-tabs>
      <b-tab title="Comandas">
        <b-btn @click="imprimirComandas">Imprimir</b-btn>

        <div class="d-flex flex-row flex-wrap" id="divComandas"
            style="width: 800px"
        >
          <div
            v-for="n in comandas"
            :key="n.comanda_id"
          >
            <div class="d-flex flex-column">
              <vue-qrcode
                :value="n.obj"
                :color="{ dark: '#000000ff', light: '#ffffffff' }"
                type="image/png"
                :width="200"
                :version="5"
                :scale="3"
                :quality="1"
                :errorCorrectionLevel="'H'"
                :margin="4"
                :qrcfg="{ ec_level: 'H', margin: 4 }"
                :logo="require('@/assets/logo.png')"
                @change="(ev) => onDataUrlChange(n, ev)"
              />
              <img
                :src="n.dataUrl"
                alt="qrcode"
              />
              <h3>Comanda n: {{ n.comanda_id }}</h3>
            </div>
          </div>
        </div>
      </b-tab>

      <b-tab title="Mesas"> </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import VueQrcode from "vue-qrcode";
import PrinterWindowHelper from '../../utils/PrinterWindowHelper';
export default {
  components: {
    VueQrcode,
  },
  props: {},
  mounted() {
    for (let i = 1; i <= 10; i++) {
      this.comandas.push({
        comanda_id: i,
        dataUrl: null,
        obj: JSON.stringify({ comanda_id: i, empresa_id: 1 }),
      });
    }
  },
  data() {
    return {
      dataUrl: null,
      comandas: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    err(er) {
      console.log("error", er);
    },
    onDataUrlChange(n, dataUrl) {
      n.dataUrl = dataUrl;
    },
    imprimirComandas(){
        PrinterWindowHelper(document.querySelector("#divComandas").innerHTML);
    }
  },
};
</script>

<style lang="scss" scoped></style>
